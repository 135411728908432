<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      limit: 20,
      page: 1,
      particular: "",
      levelTwo: [],
      search: null,
      inputLevelTwo: null,
      generalLedger: [],
      ledgerData: [],
      totalCredit: 0,
      totalDebit: 0,
      data: {},
    };
  },
  methods: {
    searchGeneralLedger(id) {
      this.ledgerData = [];
      // console.log(this.inputLevelTwo.split("-")[0]);
      this.http
        .post("transactions/ledger", {
          id,
          data: this.data,
        })
        .then((res) => {
          this.generalLedger = res.data;
          console.log(this.generalLedger);
          this.particular = res.data[0];

          for (let i = 0; i < this.generalLedger.length; i++) {
            var tot = 0;

            for (
              let j = 0;
              j < this.generalLedger[i].transaction_details.length;
              j++
            ) {
              // if (i % 2 == 0) {

              if (
                this.generalLedger[i].transaction_details[j].type == "debit"
              ) {
                tot += parseFloat(
                  this.generalLedger[i].transaction_details[j]?.value
                );
                this.totalDebit += parseFloat(
                  this.generalLedger[i].transaction_details[j]?.value
                );
                this.ledgerData.push({
                  name: this.generalLedger[i].name,
                  amount: tot,
                  debit: "ahmed",
                  debit_value:
                    this.generalLedger[i].transaction_details[j]?.value,
                  descr: this.generalLedger[i].transaction_details[j]?.descr,
                  descr_en:
                    this.generalLedger[i].transaction_details[j]?.descr_en,
                  credit: "ahmed",
                  credit_value: 0,
                  created:
                    this.generalLedger[i].transaction_details[j]?.created,
                });
              } else {
                tot -= this.generalLedger[i].transaction_details[j]?.value;
                this.totalCredit += parseFloat(
                  this.generalLedger[i].transaction_details[j]?.value
                );

                this.ledgerData.push({
                  name: this.generalLedger[i].name,
                  amount: tot,
                  debit: "ahmed",
                  debit_value: 0,
                  type: this.generalLedger[i].transaction_details[j]?.type,
                  credit: "ahmed",
                  credit_value:
                    this.generalLedger[i].transaction_details[j]?.value,
                  descr: this.generalLedger[i].transaction_details[j]?.descr,
                  descr_en:
                    this.generalLedger[i].transaction_details[j]?.descr_en,
                  created:
                    this.generalLedger[i].transaction_details[j]?.created,
                });
              }
            }
            // }
          }
          console.log(
            this.ledgerData
              .map((a) => {
                return a.debit_value;
              })
              .reduce((a, b) => parseInt(a) + parseInt(b), 0)
          );
        });
      console.log("------------------", this.ledgerData);
    },
  },
  created() {
    this.data = { from: this.$route.params.from, to: this.$route.params.to };
    console.log({ transaction: this.$route.params.account_id });
    let id = this.$route.params.id;
    this.data.from = this.$route.params.from;
    this.data.to = this.$route.params.to;
    // console.log(this.data);
    // console.log(this.$route.params.data);
    // this.inputLevelTwo = this.$route.params?.name;
    // this.getUpperAccount(this.$route.params.account_id);
    this.searchGeneralLedger(id);
  },
};
</script>

<template>
  <Layout>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :title="$i18n.locale == 'ar'
      ? particular.name
      : particular.name_en + ' ' + $t('general_ledger.ledger')
      " />

    <!-- <form @submit.prevent="searchGeneralLedger()">
      <AutoComplete
        id="autocomplete"
        @keyup="searchLevelTwo()"
        v-model="inputLevelTwo"
        :suggestions="filteredFrom"
        @complete="search = $event.query"
        style="color: #333 !important; border-radius: 10px !important"
      />

      <button
        style="position: relative; bottom: 4px; left: 20px"
        type="submit"
        class="btn btn-primary mt-2 text-align-center"
      >
        {{ $t("popups.search") }}
      </button>
    </form> -->
 
    <table style="margin-top: 29px" class="table table-striped" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <thead>
        <tr class="text-light" style="background-color: #2a3042 !important">
          <th scope="col">{{ $t("general_ledger.date") }}</th>
          <th scope="col">{{ $t("general_ledger.descr") }}</th>
          <th scope="col">{{ $t("general_ledger.debit") }}</th>
          <th scope="col">{{ $t("general_ledger.credit") }}</th>
          <th scope="col">{{ $t("general_ledger.balance") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(general, index) in ledgerData" :key="general">
          <td>
            {{ general?.created?.split("T")[0] }}
          </td>
          <td>
            <span>{{
              $i18n.locale == "ar" ? general?.descr : general?.descr_en
            }}</span>
          </td>
          <td>
            <span v-if="general.debit_value != 0">{{
              parseFloat(general?.debit_value).toLocaleString()
            }}</span>
            <span v-if="general.debit_value == 0"></span>
          </td>
          <td>
            <span v-if="general.credit_value != 0">{{
              parseFloat(general?.credit_value).toLocaleString()
            }}</span>
            <span v-if="general.credit_value == 0"></span>
          </td>
          <td>
            <span>
              {{
                general.type == "credit"
                ? parseFloat(general.credit_value) +
                parseFloat(ledgerData[index + 1]?.credit_value || 0) -
                parseFloat(general.debit_value)
                : parseFloat(general.debit_value) +
                parseFloat(ledgerData[index + 1]?.debit_value || 0) -
                parseFloat(general.credit_value)
              }}
            </span>
            <!-- <span v-if="general.debit_value < general.credit_value">
              {{
                (
                  (parseFloat(general?.credit_value) +
                    parseFloat(ledgerData[index + 1]?.credit_value) || 0) -
                  parseFloat(general.debit_value)
                ).toLocaleString()
              }}
            </span> -->
          </td>
        </tr>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col" class="total-style">
            {{ totalDebit.toLocaleString() }}
          </th>
          <th scope="col" class="total-style">
            {{ totalCredit.toLocaleString() }}
          </th>
          <th scope="col" class="total-style">
            {{
              totalCredit > totalDebit
              ? (
                parseFloat(totalCredit) - parseFloat(totalDebit)
              ).toLocaleString()
              : (
                parseFloat(totalDebit) - parseFloat(totalCredit)
              ).toLocaleString()
            }}
          </th>
        </tr>
      </tbody>
    </table>
  </Layout>
</template>
<style scoped></style>
